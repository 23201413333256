var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('form',{staticClass:"content__layout",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"content__main"},[_c('section',[_c('div',{staticClass:"back"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":_vm.arrowBackIcon,"alt":"Back to Cart"}}),_c('span',[_vm._v("Back to Cart")])])],1),_c('div',{staticClass:"heading"},[_c('h1',{staticClass:"montserrat"},[_vm._v("Delivery Details")]),_c('div',{staticClass:"checkbox__group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendAsDropshipper),expression:"sendAsDropshipper"}],attrs:{"type":"checkbox","name":"sendAsDropshipper","id":"sendAsDropshipper"},domProps:{"checked":Array.isArray(_vm.sendAsDropshipper)?_vm._i(_vm.sendAsDropshipper,null)>-1:(_vm.sendAsDropshipper)},on:{"change":function($event){var $$a=_vm.sendAsDropshipper,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.sendAsDropshipper=$$a.concat([$$v]))}else{$$i>-1&&(_vm.sendAsDropshipper=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.sendAsDropshipper=$$c}}}}),_vm._m(0)])]),_c('div',{staticClass:"body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"form__col"},[_c('div',{staticClass:"form__group",class:{
                  invalid: _vm.$v.email.$error,
                  valid: !_vm.$v.email.error && _vm.$v.email.$dirty,
                }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","name":"email","id":"email","placeholder":" ","autocomplete":"off","data-lpignore":"true"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),(_vm.$v.email.$error && !_vm.$v.email.required)?_c('div',{staticClass:"error"},[_vm._v(" *Field is required ")]):_vm._e(),(_vm.$v.email.$error && !_vm.$v.email.email)?_c('div',{staticClass:"error"},[_vm._v(" *Not a valid email ")]):_vm._e()]),_c('div',{staticClass:"form__group",class:{
                  invalid: _vm.$v.phone.$error,
                  valid: !_vm.$v.phone.error && _vm.$v.phone.$dirty,
                }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],attrs:{"type":"text","name":"phone","id":"phone","placeholder":" ","autocomplete":"off","data-lpignore":"true"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('label',{attrs:{"for":"phone"}},[_vm._v("Phone Number")]),(_vm.$v.phone.$error && !_vm.$v.phone.required)?_c('div',{staticClass:"error"},[_vm._v(" *Field is required ")]):_vm._e(),(_vm.$v.phone.$error && !_vm.$v.phone.minLength)?_c('div',{staticClass:"error"},[_vm._v(" *Phone number cannot less than 6 digit ")]):_vm._e(),(_vm.$v.phone.$error && !_vm.$v.phone.maxLength)?_c('div',{staticClass:"error"},[_vm._v(" *Phone number cannot more than 20 digit ")]):_vm._e(),(_vm.$v.phone.$error && !_vm.$v.phone.isPhoneInvalid)?_c('div',{staticClass:"error"},[_vm._v(" *Invalid Phone Number (Accepted Letters: 0-9,-,+,(,)) ")]):_vm._e()]),_c('div',{staticClass:"form__group",class:{
                  invalid: _vm.$v.address.$error,
                  valid: !_vm.$v.address.error && _vm.$v.address.$dirty,
                }},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],attrs:{"name":"address","id":"address","placeholder":" ","autocomplete":"off","data-lpignore":"true","maxlength":"120"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),_c('label',{attrs:{"for":"address"}},[_vm._v("Delivery Address")]),(_vm.$v.address.$error && !_vm.$v.address.required)?_c('div',{staticClass:"error"},[_vm._v(" *Field is required ")]):_vm._e(),(_vm.$v.address.$error && !_vm.$v.address.maxLength)?_c('div',{staticClass:"error"},[_vm._v(" *Field cannot more than 120 characters ")]):_vm._e()])]),_c('div',{staticClass:"form__col"},[_c('div',{staticClass:"form__group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dropShipperName),expression:"dropShipperName"}],attrs:{"type":"text","name":"dropShipperName","id":"dropShipperName","placeholder":" ","autocomplete":"off","data-lpignore":"true","disabled":_vm.sendAsDropshipper === false},domProps:{"value":(_vm.dropShipperName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.dropShipperName=$event.target.value}}}),_c('label',{attrs:{"for":"dropShipperName"}},[_vm._v(" Dropshipper Name ")])]),_c('div',{staticClass:"form__group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dropShipperPhone),expression:"dropShipperPhone"}],attrs:{"type":"text","name":"dropShipperPhone","id":"dropShipperPhone","placeholder":" ","autocomplete":"off","data-lpignore":"true","disabled":_vm.sendAsDropshipper === false},domProps:{"value":(_vm.dropShipperPhone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.dropShipperPhone=$event.target.value}}}),_c('label',{attrs:{"for":"dropShipperPhone"}},[_vm._v("Dropshipper Phone Number")])])])])])])]),_c('aside',{staticClass:"content__aside"},[_c('Summary')],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"sendAsDropshipper"}},[_c('span'),_c('span',[_vm._v("Send as dropshipper")])])}]

export { render, staticRenderFns }