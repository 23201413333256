<template>
  <div class="content">
    <div class="content__layout">
      <div class="content__main thank-you">
        <section>
          <div class="heading">
            <h1 class="montserrat">Thank You</h1>
          </div>
          <div class="body">
            <strong>Order ID: {{ orderId ? orderId : "-" }}</strong>
            <p v-if="shipmentMethod">
              Your order will be delivered {{ shipmentMethod.estimate }} with
              {{ shipmentMethod.label.toLowerCase() }}
            </p>

            <div class="back">
              <router-link to="/">
                <img :src="arrowBackIcon" alt="Go to Homepage" />
                <span>Go to Homepage</span>
              </router-link>
            </div>
          </div>
        </section>
      </div>
      <aside class="content__aside"><Summary /></aside>
    </div>
  </div>
</template>

<script>
import Summary from "@/components/Summary/Summary.vue";
import arrowBackIcon from "@/assets/icons/arrow-back.svg";

export default {
  name: "ThankYou",

  data() {
    return {
      arrowBackIcon,
    };
  },

  components: {
    Summary,
  },

  props: ["orderId"],

  computed: {
    shipmentMethod: {
      get() {
        return this.$store.state.shipment.method;
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
.thank-you
  align-items: center;
  justify-content: center;

section .body strong
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;

section .body p
  font-size: 14px;
  line-height: 17px;

section .body .back
  margin-top: 60px;
  margin-bottom: 0;
</style>
