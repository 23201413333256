<template>
  <div class="page__paper">
    <div class="page__container">
      <div class="step__breadcrumb">
        <ul>
          <li :class="step >= 1 ? 'step__done' : ''">
            <span>Delivery</span>
            <span>
              <img :src="arrowRightIcon" alt="Arrow Right" />
            </span>
          </li>
          <li :class="step >= 2 ? 'step__done' : ''">
            <span>Payment</span>
            <span>
              <img :src="arrowRightIcon" alt="Arrow Right" />
            </span>
          </li>
          <li :class="step === 3 ? 'step__done' : ''">
            <span>Finish</span>
          </li>
        </ul>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import arrowRightIcon from "@/assets/icons/arrow-right.svg";

export default {
  name: "App",

  data() {
    return {
      arrowRightIcon,
      pathname: this.$route.path,
      step: 1,
    };
  },

  watch: {
    $route(to) {
      console.log(to);
      if (to.path === "/") {
        this.step = 1;
      } else if (to.path === "/payment") {
        this.step = 2;
      } else if (to.path === "/thank-you") {
        this.step = 3;
      }
    },
  },

  mounted() {
    if (this.pathname === "/") {
      this.step = 1;
    } else if (this.pathname === "/payment") {
      this.step = 2;
    } else if (this.pathname === "/thank-you") {
      this.step = 3;
    }
  },
};
</script>

<style lang="stylus">
*
  margin: 0;
  padding: 0;
  box-sizing: border-box;

html, body, #app, .page__paper, .page__container
  display: flex;
  flex-direction: columns;
  flex: 1;
  min-height: 100%;
  height: auto;

body
  font-family: 'Inter', sans-serif;
  background: #FFFAE6;
  padding: 20px 10px;

  @media (min-width: 768px)
    padding: 55px 50px;

.montserrat
  font-family: 'Montserrat', sans-serif;

.page__paper
  background: #fff;

.page__container
  width: 100%;
  position: relative;

.step__breadcrumb
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  text-align: center;
  width: 100%;
  max-width: 350px;

  @media (min-width: 768px)
    top: -29px;
    max-width: 520px;

.step__breadcrumb ul
  list-style: none;
  display: flex;
  justify-content : center;
  padding: 16px 12px;
  margin: 0 auto;
  background: #FFFAE6;
  border-radius: 35px;
  counter-reset: breadcrumb-counter;
  width: 100%;

  @media (min-width: 768px)
    padding: 20px 40px;

.step__breadcrumb li
  counter-increment: breadcrumb-counter;
  padding-left: 30px;
  position: relative;
  font-size: 12px;

  @media (min-width: 768px)
    font-size: 16px;
    padding-left: 48px;

.step__breadcrumb li::before
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: counter(breadcrumb-counter);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: rgba(255,138,0, 0.2);
  border-radius: 50%;
  color: #FF8A00;

  @media (min-width: 768px)
    width: 30px;
    height: 30px;

.step__breadcrumb li.step__done::before
  background: #FF8A00;
  color: #fff;

.step__breadcrumb li span
  color: #FF8A00;
  font-weight: 500;

.step__breadcrumb li:not(:last-of-type) span
  margin-right: 6px;

  @media (min-width: 480px)
    margin-right: 10px;

  @media (min-width: 768px)
    margin-right: 20px;

.step__breadcrumb li:not(:last-of-type) span img
  width: 6px;

  @media (min-width: 768px)
    width: 8px;

.content
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;


.content__layout
  flex: 1;

  @media (min-width: 992px)
    display: grid;
    grid-template-columns: 1fr 300px;

.content__main
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 60px 30px 0 30px;

  @media (min-width: 992px)
    height: 100%;
    padding: 30px;

section .heading
  margin-bottom: 30px;
  position: relative;

  @media (min-width: 768px)
    display: flex;
    align-items : center;
    justify-content: space-between;

section .heading h1
  margin: 0;
  font-size: 28px;
  color: #FF8A00;
  line-height: 44px;
  z-index: 2;
  position: relative;

  @media (min-width: 768px)
    font-size: 36px;

section .heading h1::after
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  background: #EEE;
  display: inline-block;
  width: 300px;
  max-width: 100%;
  height: 8px;
  z-index: -1;

.back
  margin-bottom: 24px;

.back a
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight :500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;

.back a:hover
  text-decoration: underline;

.back img
  margin-right: 10px;
  width: 16px;
  height: 16px;

.content__aside
  padding-top: 30px;

  @media (min-width: 992px)
    padding-top: 50px;
</style>
