import Vue from "vue";
import VueRouter from "vue-router";
import Delivery from "../views/Delivery.vue";
import Payment from "../views/Payment.vue";
import ThankYou from "../views/ThankYou.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Delivery",
    component: Delivery,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: ThankYou,
    props: true,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
