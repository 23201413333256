<template>
  <div class="content">
    <form @submit.prevent="handleSubmit" class="content__layout">
      <div class="content__main">
        <section>
          <div class="back">
            <router-link to="/">
              <img :src="arrowBackIcon" alt="Back to Cart" />
              <span>Back to Cart</span>
            </router-link>
          </div>
          <div class="heading">
            <h1 class="montserrat">Delivery Details</h1>
            <div class="checkbox__group">
              <input
                type="checkbox"
                v-model="sendAsDropshipper"
                name="sendAsDropshipper"
                id="sendAsDropshipper"
              />
              <label for="sendAsDropshipper">
                <span></span>
                <span>Send as dropshipper</span>
              </label>
            </div>
          </div>
          <div class="body">
            <form @submit.prevent="handleSubmit">
              <div class="form__col">
                <div
                  class="form__group"
                  :class="{
                    invalid: $v.email.$error,
                    valid: !$v.email.error && $v.email.$dirty,
                  }"
                >
                  <input
                    type="email"
                    name="email"
                    id="email"
                    v-model="email"
                    placeholder=" "
                    autocomplete="off"
                    data-lpignore="true"
                  />
                  <label for="email">Email</label>
                  <div
                    class="error"
                    v-if="$v.email.$error && !$v.email.required"
                  >
                    *Field is required
                  </div>
                  <div class="error" v-if="$v.email.$error && !$v.email.email">
                    *Not a valid email
                  </div>
                </div>
                <div
                  class="form__group"
                  :class="{
                    invalid: $v.phone.$error,
                    valid: !$v.phone.error && $v.phone.$dirty,
                  }"
                >
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    v-model="phone"
                    placeholder=" "
                    autocomplete="off"
                    data-lpignore="true"
                  />
                  <label for="phone">Phone Number</label>
                  <div
                    class="error"
                    v-if="$v.phone.$error && !$v.phone.required"
                  >
                    *Field is required
                  </div>
                  <div
                    class="error"
                    v-if="$v.phone.$error && !$v.phone.minLength"
                  >
                    *Phone number cannot less than 6 digit
                  </div>
                  <div
                    class="error"
                    v-if="$v.phone.$error && !$v.phone.maxLength"
                  >
                    *Phone number cannot more than 20 digit
                  </div>
                  <div
                    class="error"
                    v-if="$v.phone.$error && !$v.phone.isPhoneInvalid"
                  >
                    *Invalid Phone Number (Accepted Letters: 0-9,-,+,(,))
                  </div>
                </div>
                <div
                  class="form__group"
                  :class="{
                    invalid: $v.address.$error,
                    valid: !$v.address.error && $v.address.$dirty,
                  }"
                >
                  <textarea
                    name="address"
                    id="address"
                    v-model="address"
                    placeholder=" "
                    autocomplete="off"
                    data-lpignore="true"
                    maxlength="120"
                  ></textarea>
                  <label for="address">Delivery Address</label>
                  <div
                    class="error"
                    v-if="$v.address.$error && !$v.address.required"
                  >
                    *Field is required
                  </div>
                  <div
                    class="error"
                    v-if="$v.address.$error && !$v.address.maxLength"
                  >
                    *Field cannot more than 120 characters
                  </div>
                </div>
              </div>
              <div class="form__col">
                <div class="form__group">
                  <input
                    type="text"
                    name="dropShipperName"
                    id="dropShipperName"
                    v-model="dropShipperName"
                    placeholder=" "
                    autocomplete="off"
                    data-lpignore="true"
                    :disabled="sendAsDropshipper === false"
                  />
                  <label for="dropShipperName">
                    Dropshipper Name
                  </label>
                </div>
                <div class="form__group">
                  <input
                    type="text"
                    name="dropShipperPhone"
                    id="dropShipperPhone"
                    v-model="dropShipperPhone"
                    placeholder=" "
                    autocomplete="off"
                    data-lpignore="true"
                    :disabled="sendAsDropshipper === false"
                  />
                  <label for="dropShipperPhone">Dropshipper Phone Number</label>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>

      <aside class="content__aside"><Summary /></aside>
    </form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  maxLength,
} from "vuelidate/lib/validators";
import Summary from "@/components/Summary/Summary.vue";
import arrowBackIcon from "@/assets/icons/arrow-back.svg";

export default {
  name: "Delivery",

  data() {
    return {
      arrowBackIcon,
    };
  },

  components: {
    Summary,
  },

  computed: {
    email: {
      get() {
        return this.$store.state.deliveryInput.email;
      },
      set(value) {
        this.$store.commit("setEmail", value);
      },
    },
    phone: {
      get() {
        return this.$store.state.deliveryInput.phone;
      },
      set(value) {
        this.$store.commit("setPhone", value);
      },
    },
    address: {
      get() {
        return this.$store.state.deliveryInput.address;
      },
      set(value) {
        this.$store.commit("setAddress", value);
      },
    },
    dropShipperName: {
      get() {
        return this.$store.state.dropshipper.dropShipperName;
      },
      set(value) {
        this.$store.commit("setDropshipperName", value);
      },
    },
    dropShipperPhone: {
      get() {
        return this.$store.state.dropshipper.dropShipperPhone;
      },
      set(value) {
        this.$store.commit("setDropshipperPhone", value);
      },
    },
    sendAsDropshipper: {
      get() {
        return this.$store.state.dropshipper.sendAsDropshipper;
      },
      set(value) {
        this.$store.commit("setSendAsDropshipper", value);
      },
    },
  },

  validations: {
    email: {
      required,
      email,
    },
    phone: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
      isPhoneInvalid: (value) => {
        let regex = new RegExp(/[^0-9-+()]/g);
        return !regex.test(value);
      },
    },
    address: {
      required,
      maxLength: maxLength(120),
    },
  },

  methods: {
    handleSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      } else {
        //redirect
        this.$router.push({
          name: "Payment",
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.checkbox__group
  display: flex;
  margin-top: 30px;

  @media (min-width: 768px)
    margin-top: 0;

.checkbox__group label
  display: flex;
  flex-direction : row;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;

.checkbox__group label span:first-of-type
  display: inline-block;
  border: 2px solid #1BD97B;
  width: 20px;
  height: 20px;

.checkbox__group label span:last-of-type
  display: inline-block;
  margin-left: 10px;

.checkbox__group input[type="checkbox"]
  display: none;

.checkbox__group input[type="checkbox"]:checked + label span:first-of-type
  background-size: 14px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('~@/assets/icons/green-check.svg');

.body form
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 20px;

  @media (min-width: 768px)
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 10px;

.form__col .form__group:not(:last-of-type)
  margin-bottom :20px;

  @media (min-width: 768px)
    margin-bottom: 10px;

.form__col .form__group input
  display: block;
  width: 100%;
  padding: 19px 15px;
  border: none;
  outline: 1px solid #CCCCCC;
  line-height: 20px;
  color: #2D2A40;
  font-weight: 700;
  letter-spacing: 0.2px;

.form__col .form__group textarea
  width: 100%;
  min-height: 120px;
  padding: 19px 15px;
  border: none;
  outline: 1px solid #CCCCCC;
  resize: none;
  line-height: 20px;
  color: #2D2A40;
  font-weight: 700;
  letter-spacing: 0.2px;

.form__col .form__group
  position: relative;

.form__col .form__group label
  position: absolute;
  top: 30px;
  left: 15px;
  transform: translateY(-50%);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
  transition: all 125ms ease-in;

.form__col .form__group input:focus, .form__col .form__group textarea:focus
  outline: 1px solid #000;

.form__col .form__group input:not(:placeholder-shown)
  padding: 30px 15px 10px 15px;

.form__col .form__group input:not(:placeholder-shown) + label
  font-size: 13px;
  top: 21px;

.form__col .form__group textarea:not(:placeholder-shown)
  padding: 30px 15px 20px 15px;

.form__col .form__group textarea:not(:placeholder-shown) + label
  font-size: 13px;
  top: 21px;

.form__col .form__group.valid input:not(:placeholder-shown),
.form__col .form__group.invalid input:not(:placeholder-shown),
.form__col .form__group.valid textarea:not(:placeholder-shown),
.form__col .form__group.invalid textarea:not(:placeholder-shown)
  padding: 30px 38px 10px 15px;

.form__col .form__group.valid::after,
.form__col .form__group.invalid::after
  content: '';
  position: absolute;
  top: 30px;
  right: 15px;
  transform: translateY(-50%);
  background-size: 14px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;

.form__col .form__group.valid::after
  background-image: url('~@/assets/icons/green-check.svg');

.form__col .form__group.invalid::after
  background-image: url('~@/assets/icons/orange-x.svg');

.form__col .form__group.valid input,
.form__col .form__group.valid textarea
  outline: 1px solid #1BD97B;

.form__col .form__group.valid input + label,
.form__col .form__group.valid textarea + label
  color: #1BD97B;

.form__col .form__group.invalid input,
.form__col .form__group.invalid textarea
  outline: 1px solid #FF8A00;

.form__col .form__group.invalid input + label,
.form__col .form__group.invalid textarea + label
  color: #FF8A00;

.form__col .form__group .error
  color: rgb(229, 62, 62);
  font-size: 12px;
  font-weight: 600;
  margin-top: 6px;
</style>
