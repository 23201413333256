<template>
  <div class="summary">
    <div class="summary__heading">
      <h2 class="montserrat">Summary</h2>
      <p>{{ totalItems }} items purchased</p>
    </div>
    <div class="summary__body">
      <div
        class="summary__shipment"
        v-if="
          shipmentMethod &&
            (pathname === '/payment' || pathname === '/thank-you')
        "
      >
        <hr />
        <p>Delivery Estimation</p>
        <p>{{ shipmentMethod.estimate }} by {{ shipmentMethod.label }}</p>
      </div>
      <div
        class="summary__payment-method"
        v-if="paymentMethod && pathname === '/thank-you'"
      >
        <hr />
        <p>Payment Method</p>
        <p>{{ paymentMethod.label }}</p>
      </div>
    </div>

    <div class="summary__footer">
      <div class="summary__cost">
        <div>Cost of Goods</div>
        <div class="summary__cost-value">{{ costOfGoods }}</div>
      </div>
      <div class="summary__cost" v-if="dropshipper.sendAsDropshipper">
        <div>Dropshipping Fee</div>
        <div class="summary__cost-value">
          {{ dropshipper.dropshippingFee.toLocaleString() }}
        </div>
      </div>
      <div
        class="summary__cost"
        v-if="
          shipmentMethod &&
            (pathname === '/payment' || pathname === '/thank-you')
        "
      >
        <div>
          <strong>{{ shipmentMethod.label }}</strong> shipment
        </div>
        <div class="summary__cost-value">
          {{ shipmentMethod.value.toLocaleString() }}
        </div>
      </div>
      <div class="summary__total montserrat">
        <div>Total</div>
        <div>{{ totalCost }}</div>
      </div>
      <div
        class="summary__continue"
        v-if="pathname === '/' || pathname === '/payment'"
      >
        <button type="submit">
          {{
            pathname === "/"
              ? "Continue to Payment"
              : paymentMethod && pathname === "/payment"
              ? `Pay with ${paymentMethod.label.toLowerCase()}`
              : "Select Options First"
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pathname: this.$route.path,
    };
  },

  computed: {
    totalItems: {
      get() {
        return this.$store.state.summary.totalItems.toLocaleString();
      },
    },
    costOfGoods: {
      get() {
        return this.$store.state.summary.costOfGoods.toLocaleString();
      },
    },
    totalCost: {
      get() {
        let totalCost = this.$store.state.summary.totalCost;

        if (this.dropshipper.sendAsDropshipper) {
          totalCost += this.dropshipper.dropshippingFee;
        }

        if (
          this.shipmentMethod &&
          (this.pathname === "/payment" || this.pathname === "/thank-you")
        ) {
          totalCost += this.shipmentMethod.value;
        }

        return totalCost.toLocaleString();
      },
    },
    dropshipper: {
      get() {
        return this.$store.state.dropshipper;
      },
    },
    paymentMethod: {
      get() {
        return this.$store.state.payment.method;
      },
    },
    shipmentMethod: {
      get() {
        return this.$store.state.shipment.method;
      },
    },
  },
};
</script>

<style lang="stylus">
.summary
  padding: 30px 20px 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: solid 1px rgba(255, 138, 0, 0.2);

  @media (min-width: 992px)
    border-top: none;
    border-left: solid 1px rgba(255, 138, 0, 0.2);

.summary__heading h2
  margin: 0;
  margin-bottom: 10px;
  font-size: 24px;
  color: #FF8A00;
  line-height: 26px;

.summary__heading p
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);

.summary__cost
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-column-gap: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;

.summary__cost-value
  font-weight: 700;
  color: #000;

.summary__cost:not(:last-of-type)
  margin-bottom: 12px;

.summary__body
  flex: 1;
  margin-bottom: 21px;

.summary__shipment hr,
.summary__payment-method hr
  margin-top: 21px;
  margin-bottom: 21px;
  border-top: solid 1px #D8D8D8;
  width: 80px;

.summary__shipment p:first-of-type,
.summary__payment-method p:first-of-type
  font-weight: 500;
  color: #000;
  font-size: 14px;
  margin-bottom: 4px;

.summary__shipment p:last-of-type,
.summary__payment-method p:last-of-type
  color: #1BD97B;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

.summary__total
  margin-top: 24px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 12px;
  justify-content: space-between;
  color: #FF8A00;
  font-weight: 700;
  font-size: 24px;

.summary__continue button
  width: 100%;
  padding: 20px 32px;
  border: none;
  color: #fff;
  cursor: pointer;
  background: #FF8A00;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 3px 5px 10px rgba(255, 138, 0, 0.2);

.summary__continue button[disabled]
  opacity: 0.5;
  cursor: initial;
</style>
