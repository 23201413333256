import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    deliveryInput: {
      email: "",
      phone: "",
      address: "",
    },
    summary: {
      costOfGoods: 500000,
      totalItems: 10,
      totalCost: 500000,
    },
    dropshipper: {
      sendAsDropshipper: false,
      dropShipperName: "",
      dropShipperPhone: "",
      dropshippingFee: 5900,
    },
    payment: {
      method: null,
    },
    shipment: {
      method: null,
    },
  },
  mutations: {
    setEmail: (state, email) => {
      state.deliveryInput.email = email;
    },
    setPhone: (state, phone) => {
      state.deliveryInput.phone = phone;
    },
    setAddress: (state, address) => {
      state.deliveryInput.address = address;
    },
    setDropshipperName: (state, dropShipperName) => {
      state.dropshipper.dropShipperName = dropShipperName;
    },
    setDropshipperPhone: (state, dropShipperPhone) => {
      state.dropshipper.dropShipperPhone = dropShipperPhone;
    },
    setSendAsDropshipper: (state, sendAsDropshipper) => {
      state.dropshipper.sendAsDropshipper = sendAsDropshipper;
    },
    setShipmentMethod: (state, shipmentMethod) => {
      state.shipment.method = shipmentMethod;
    },
    setPaymentMethod: (state, paymentMethod) => {
      state.payment.method = paymentMethod;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
