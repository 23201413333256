<template>
  <div class="content">
    <form @submit.prevent="handleSubmit" class="content__layout">
      <div class="content__main">
        <section>
          <div class="back">
            <router-link to="/">
              <img :src="arrowBackIcon" alt="Back to Delivery" />
              <span>Back to Delivery</span>
            </router-link>
          </div>
          <div class="heading">
            <h1 class="montserrat">Shipment</h1>
          </div>
          <div class="body">
            <div class="radio__fields">
              <div>
                <div class="radio__box">
                  <input
                    type="radio"
                    name="shipmentMethod"
                    id="goSend"
                    v-model="shipmentMethod"
                    value="1"
                  />
                  <label for="goSend">
                    <span>GO-SEND</span>
                    <strong>15,000</strong>
                  </label>
                </div>
              </div>
              <div>
                <div class="radio__box">
                  <input
                    type="radio"
                    name="shipmentMethod"
                    id="jne"
                    v-model="shipmentMethod"
                    value="2"
                  />
                  <label for="jne">
                    <span>JNE</span>
                    <strong>9,000</strong>
                  </label>
                </div>
              </div>
              <div>
                <div class="radio__box">
                  <input
                    type="radio"
                    name="shipmentMethod"
                    id="personalCourier"
                    v-model="shipmentMethod"
                    value="3"
                  />
                  <label for="personalCourier">
                    <span>Personal Courier</span>
                    <strong>29,000</strong>
                  </label>
                </div>
              </div>
            </div>
            <div
              class="error"
              v-if="$v.shipmentMethod.$error && !$v.shipmentMethod.required"
            >
              *Please choose shipment option
            </div>
          </div>
        </section>
        <section>
          <div class="heading">
            <h1 class="montserrat">Payment</h1>
          </div>
          <div class="body">
            <div class="radio__fields">
              <div class="radio__box">
                <input
                  type="radio"
                  name="paymentMethod"
                  id="eWallet"
                  v-model="paymentMethod"
                  value="1"
                />
                <label for="eWallet">
                  <span>e-Wallet</span>
                  <strong>1,500,000 left</strong>
                </label>
              </div>
              <div class="radio__box radio__box--label-only">
                <input
                  type="radio"
                  name="paymentMethod"
                  id="bankTransfer"
                  v-model="paymentMethod"
                  value="2"
                />
                <label for="bankTransfer">
                  <span>Bank Transfer</span>
                  <strong></strong>
                </label>
              </div>
              <div class="radio__box radio__box--label-only">
                <input
                  type="radio"
                  name="paymentMethod"
                  id="virtualAccount"
                  v-model="paymentMethod"
                  value="3"
                />
                <label for="virtualAccount">
                  <span>Virtual Account</span>
                  <strong></strong>
                </label>
              </div>
            </div>
            <div
              class="error"
              v-if="$v.paymentMethod.$error && !$v.paymentMethod.required"
            >
              *Please choose payment option
            </div>
          </div>
        </section>
      </div>
      <aside class="content__aside"><Summary /></aside>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Summary from "@/components/Summary/Summary.vue";
import arrowBackIcon from "@/assets/icons/arrow-back.svg";

const generateId = (length, characters) => {
  let result = "";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export default {
  name: "Payment",

  data() {
    return {
      arrowBackIcon,
    };
  },

  components: {
    Summary,
  },

  computed: {
    paymentMethod: {
      get() {
        let data = this.$store.state.payment.method
          ? this.$store.state.payment.method.id
          : null;

        return data;
      },
      set(value) {
        let methods = [
          { id: 1, label: "e-Wallet", value: 1500000 },
          { id: 2, label: "Bank Transfer", value: null },
          { id: 3, label: "Virtual Account", value: null },
        ];
        this.$store.commit(
          "setPaymentMethod",
          methods.find((method) => method.id === Number(value))
        );
      },
    },
    shipmentMethod: {
      get() {
        let data = this.$store.state.shipment.method
          ? this.$store.state.shipment.method.id
          : null;

        return data;
      },
      set(value) {
        let methods = [
          { id: 1, label: "GO-SEND", value: 15000, estimate: "today" },
          { id: 2, label: "JNE", value: 9000, estimate: "2 days" },
          { id: 3, label: "Personal Courier", value: 29000, estimate: "1 day" },
        ];

        this.$store.commit(
          "setShipmentMethod",
          methods.find((method) => method.id === Number(value))
        );
      },
    },
  },

  validations: {
    paymentMethod: {
      required,
    },
    shipmentMethod: {
      required,
    },
  },

  methods: {
    handleSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      } else {
        //generate random order id
        let exclude = ["1", "I", "0", "O"];
        let alphaNumeric =
          "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

        let filteredAlphaNumeric = alphaNumeric
          .split("")
          .filter((letter) => exclude.indexOf(letter) < 0)
          .join("");

        let orderId = generateId(5, filteredAlphaNumeric);

        //redirect
        this.$router.push({
          name: "ThankYou",
          params: {
            orderId,
          },
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
section:not(:first-of-type)
  margin-top: 60px;

.radio__fields
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px)
  grid-gap: 20px;

.radio__fields + .error
  color: rgb(229, 62, 62);
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;

.radio__box input[type="radio"]
  display: none;

.radio__box label
  display: block;
  outline: 1px solid #ccc;
  padding: 12px 15px;
  min-height: 60px;
  cursor: pointer;
  position: relative;

.radio__box input[type="radio"]:checked + label
  outline: 2px solid #1BD97B;
  background: rgba(27, 217, 123, 0.1);
  padding-right: 38px;

.radio__box input[type="radio"]:checked + label::after
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: url('~@/assets/icons/green-check.svg');
  background-size: 14px;
  background-position: center center;
  background-repeat:no-repeat;
  width: 18px;
  height: 18px;

.radio__box label span
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;

  @media (min-width: 768px)
    font-size: 13px;

.radio__box input[type="radio"]:checked + label span
  color: #000;

.radio__box label strong
  margin-top: 4px;
  display: block;
  color: #2D2A40;
  color: rgba(45,42,64, 0.6)
  font-size: 14px;

  @media (min-width: 768px)
    margin-top: 2px;
    font-size: 16px;

.radio__box input[type="radio"]:checked + label strong
  color: rgba(45,42,64,1)

.radio__box--label-only label
  display: flex;
  align-items: center;

.radio__box--label-only label span
  font-size: 14px;
  font-weight: 500;

  @media (min-width: 768px)
    font-size: 16px;
</style>
